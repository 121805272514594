import { configureStore } from '@reduxjs/toolkit'
import uiSlice from './uiSlice'
import toastSlice from './toastSlice'
import confirmSlice from './confirmSlice'

const store = configureStore({
  reducer: {
    ui: uiSlice,
    toast: toastSlice,
    confirm: confirmSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

export default store
