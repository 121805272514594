// src/components/ConfirmDialog.js

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import { closeConfirmDialog, confirmAction } from '../store/confirmSlice';

const ConfirmDialog = () => {
  const dispatch = useDispatch();
  const confirm = useSelector((state) => state.confirm);

  const handleClose = () => dispatch(closeConfirmDialog());
  const handleConfirm = () => dispatch(confirmAction());

  return (
    <CModal visible={confirm.show} onClose={handleClose}>
      <CModalHeader closeButton data-testid="action-header">Confirm Action</CModalHeader>
      <CModalBody>{confirm.message}</CModalBody>

      {confirm.isPrompt ? (
        <CModalFooter>
          <CButton color="secondary" onClick={handleClose} data-testid="cancel-button">
            Cancel
          </CButton>
          <CButton color="primary" onClick={handleConfirm} data-testid="confirm-button">
            Confirm
          </CButton>
        </CModalFooter>
      ) : (
        <CModalFooter>
          <CButton color="secondary" onClick={handleClose} data-testid="Ok-button">
            Ok
          </CButton>
        </CModalFooter>
      )}
    </CModal>
  );
};

export default ConfirmDialog;
