import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";

import "./index.css";
import store from "./store";
import ToastManager from "./components/toasts/ToastManager";
import ConfirmDialog from "./components/ConfirmDialog";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <ConfirmDialog />
      <App />
      <ToastManager />
    </Provider>
  </React.Fragment>
);
