// src/store/slices/confirmSlice.js

import { createSlice } from '@reduxjs/toolkit';

const confirmSlice = createSlice({
  name: 'confirm',
  initialState: {
    show: false,
    message: '',
    onConfirm: null,
    isPrompt: true,
  },
  reducers: {
    showConfirmDialog: (state, action) => {
      state.show = true;
      state.message = action.payload.message;
      state.onConfirm = action.payload.onConfirm;
      state.isPrompt = !!action.payload.onConfirm;
    },
    closeConfirmDialog: (state) => {
      state.show = false;
      state.message = '';
      state.onConfirm = null;
      state.isPrompt = true;
    },
    confirmAction: (state) => {
      state.onConfirm && state.onConfirm();
      state.show = false;
    },
  },
});

export const { showConfirmDialog, closeConfirmDialog, confirmAction } = confirmSlice.actions;

export default confirmSlice.reducer;
