// App.js
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CSpinner } from '@coreui/react';
import './scss/style.scss';
import './scss/_custom.scss';

const DefaultLayout = React.lazy(() => import('./layout/default-layout/DefaultLayout.js'));
const AuthLayout = React.lazy(() => import('./layout/auth-layout/AuthLayout.js'));

const App = () => {
  return (
    <div data-testid="app-root">
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="pt-3 text-center h-[100vh] flex justify-center items-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >
          <Routes>
            <Route path="/auth/*" name="Auth" element={<AuthLayout />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
